/**
 * A custom useEffect hook that animates and scales an image up and optionally inverts its position on hover
 * @param {Ref} wrapperRef
 * @param {Ref} imageRef
 * @param {Boolean} invertPosition
 * @param {Int} maxZoom
 */
import { useEffect } from "react";
import useMouse from "@react-hook/mouse-position";
import { gsap } from "gsap";

export default function useScaleImageEffect(wrapperRef, imageRef, invertPosition, maxZoom) {
  const mouse =
    typeof window !== `undefined`
      ? useMouse(wrapperRef, {
          enterDelay: 10,
          leaveDelay: 10,
        })
      : null;

  const SHIFT_LIMIT = 20;
  const maxScale = maxZoom ? maxZoom : 1.125;

  function returnScaledNum(min, max, x) {
    return x * (max - min) - (max - min) / 2;
  }

  useEffect(() => {
    const imageWidth = imageRef.current.clientWidth;
    const imageHeight = imageRef.current.clientHeight;
    const hoverWidthRatio = mouse.x / imageWidth;
    const hoverHeightRatio = mouse.y / imageHeight;

    const effectDuration = 0.4;

    if (mouse.isOver) {
      const xProgress = returnScaledNum(SHIFT_LIMIT * -1, SHIFT_LIMIT, hoverWidthRatio);
      const yProgress = returnScaledNum(SHIFT_LIMIT * -1, SHIFT_LIMIT, hoverHeightRatio);

      gsap.to(imageRef.current, { duration: effectDuration, scale: maxScale });
      if (invertPosition) {
        gsap.to(imageRef.current, {
          duration: effectDuration,
          x: xProgress * -1,
          y: yProgress * -1,
        });
      } else {
        gsap.to(imageRef.current, {
          duration: effectDuration,
          y: 32,
        });
      }
    } else {
      gsap.to(imageRef.current, { duration: effectDuration, scale: 1.01 });
      gsap.to(imageRef.current, { duration: effectDuration, x: 0, y: 0 });
    }
  }, [mouse]);
}
