import React from "react";
import { large } from "./typography.module.css";
import { cn } from "../lib/helpers";
import styles from "./lottie-brand-card.module.css";
import PropTypes from "prop-types";
import BrandCard from "./brand-card";
import LottieSection from "./lottie-section";

const LottieBrandCard = (props) => {
  const {
    backgroundColor,
    parallax,
    parallaxSpeedMultiplier,
    lottieFile,
    loop,
    isOnTeamPage,
  } = props;

  return (
    <BrandCard
      backgroundColor={backgroundColor}
      parallax={parallax}
      parallaxSpeedMultiplier={parallaxSpeedMultiplier}
      isOnTeamPage={isOnTeamPage}
    >
      <div className={cn(styles.root, large)}>
        <LottieSection lottieFile={lottieFile} loop={loop} disablePadding />
      </div>
    </BrandCard>
  );
};

LottieBrandCard.propTypes = {
  lottieFile: PropTypes.object,
  backgroundColor: PropTypes.string,
  parallax: PropTypes.bool,
  parallaxSpeedMultiplier: PropTypes.number,
  loop: PropTypes.bool,
};

export default LottieBrandCard;
