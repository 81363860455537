import React from "react";
import { large } from "./typography.module.css";
import { buildImageObj, cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import styles from "./image-brand-card.module.css";
import PropTypes from "prop-types";
import BrandCard from "./brand-card";

const ImageBrandCard = (props) => {
  const {
    image,
    backgroundColor,
    parallax,
    fullbleed,
    parallaxSpeedMultiplier,
    isOnTeamPage,
  } = props;

  return (
    <BrandCard
      backgroundColor={backgroundColor}
      parallax={parallax}
      fullBleed={fullbleed}
      parallaxSpeedMultiplier={parallaxSpeedMultiplier}
      isOnTeamPage={isOnTeamPage}
    >
      <div className={cn(styles.root, large)}>
        <img
          className={styles.mobileLogoGrid}
          src={imageUrlFor(buildImageObj(image)).auto("format").quality(100).url()}
          alt="Capacity"
        />
      </div>
    </BrandCard>
  );
};

ImageBrandCard.propTypes = {
  image: PropTypes.object,
  backgroundColor: PropTypes.string,
  fullbleed: PropTypes.bool,
  parallax: PropTypes.bool,
  parallaxSpeedMultiplier: PropTypes.number,
};

export default ImageBrandCard;
