import React, { useEffect, useRef } from "react";
import Rellax from "rellax";
import PropTypes from "prop-types";
import styles from "./brand-card.module.css";
import { cn } from "../lib/helpers";
import { useMediaQuery } from "react-responsive";
import { mediaMaxSmall } from "../lib/mediaQueries";

const BrandCard = (props) => {
  const {
    children,
    backgroundColor,
    parallax,
    parallaxSpeedMultiplier,
    fullBleed,
    isOnTeamPage,
  } = props;
  const rootRef = useRef(null);

  const isMobile = useMediaQuery(mediaMaxSmall);

  const MOBILE_SPEED = 0.6;
  const DESKTOP_SPEED = 1;
  const MOBILE_SPEED_FAST = 2.5;
  const DESKTOP_SPEED_FAST = 5;

  const getSpeed = () => {
    if (isMobile) {
      if (fullBleed) {
        return MOBILE_SPEED_FAST;
      }
      return MOBILE_SPEED;
    } else {
      if (fullBleed) {
        return DESKTOP_SPEED_FAST;
      }
      return DESKTOP_SPEED;
    }
  };

  useEffect(() => {
    if (parallax) {
      const rellaxInstance = new Rellax(rootRef.current, {
        center: true,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false,
        speed: getSpeed() * (parallaxSpeedMultiplier ? parallaxSpeedMultiplier : 1),
      });
      return () => {
        //reset for page changes
        rellaxInstance.destroy();
      };
    }
  });
  return (
    <div
      className={cn(
        styles.root,
        fullBleed ? styles.disablePadding : "",
        backgroundColor ? styles[backgroundColor] : styles.bgGray,
        isOnTeamPage ? styles.isOnTeamPage : ""
      )}
    >
      <div className={styles.wrapper} ref={rootRef}>
        {children}
      </div>
    </div>
  );
};

BrandCard.propTypes = {
  children: PropTypes.object,
  backgroundColor: PropTypes.string,
  parallax: PropTypes.bool,
  parallaxSpeedMultiplier: PropTypes.number,
  fullBleed: PropTypes.bool,
  isOnTeamPage: PropTypes.bool,
};

export default BrandCard;
