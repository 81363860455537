import React from "react";
import { large } from "./typography.module.css";
import { cn } from "../lib/helpers";
import BrandCard from "./brand-card";

import styles from "./text-brand-card.module.css";
import { bodyTextBlock } from "./typography.module.css";

import PortableText from "./portableText";

import PropTypes from "prop-types";

const TextBrandCard = (props) => {
  const {
    textBlocks,
    backgroundColor,
    parallax,
    parallaxSpeedMultiplier,
    verticalCenter,
    textColor,
    isOnTeamPage,
  } = props;
  return (
    <BrandCard
      backgroundColor={backgroundColor}
      parallax={parallax}
      parallaxSpeedMultiplier={parallaxSpeedMultiplier}
      isOnTeamPage={isOnTeamPage}
    >
      <div
        className={cn(
          styles.root,
          bodyTextBlock,
          textColor ? styles[textColor] : styles.textLightBlue,
          verticalCenter ? styles.verticalCenter : ""
        )}
      >
        <PortableText blocks={textBlocks} />
      </div>
    </BrandCard>
  );
};

TextBrandCard.propTypes = {
  textBlocks: PropTypes.array,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  verticalCenter: PropTypes.bool,
  parallax: PropTypes.bool,
  parallaxSpeedMultiplier: PropTypes.number,
};

export default TextBrandCard;
